<template>
  <div class="wrap">
    <div class="header">
      <img src="/images/story/banner.png" class="town-pic"/>
      <img src="/images/story/title.png" class="title-img">
    </div>
    <div class="center-box" v-if="Object.keys(dataSource.data).length>0">
      <div style=" padding: 0.66rem 0;">
        <NewsHeader :dataSource="dataSource.data"/>

        <div class="player-box" @click="playClick" v-show="$fileType.isVideo(dataSource.data.video)">
          <video ref="videoPlayer" class="video" :src="dataSource.data.video"
          ></video>
          <div class="player-cover-box" v-show="!isPlay">
            <img src="/images/common/play.png" class="playImg">
          </div>
        </div>
        <AudoPlayer :data="dataSource.data" v-show="!$fileType.isVideo(dataSource.data.video)"/>
        <div class="main-content" v-html="dataSource.data?.content"></div>
      </div>

      <div class="center-right-box" v-if="!townPage">
        <ul>
          <li v-for="(v,index) in list" :key="index" @click="changeShowIndex(index)">
            <div class="right-item">
              <div class="right-item-top-box">
                <img :src="v.picture" class="right-img">
                <div>
                  <p class="right-item-title" :style="v.id===selectedId?{color:'#01398D'}:{color:'#333333'}">
                    {{ v.title }}
                  </p>
                  <p class="right-item-author">作者:{{ v.townName }}</p>
                </div>
              </div>
              <p class="right-item-desc">
                {{ v.description }}
              </p>
              <div class="right-item-bottom-box">
                <div class="right-item-bottom-line"/>
                <p class="right-item-date">
                  {{ $date.moment(v.createDate, 'YYYY-MM-DD') }}
                </p>
              </div>
            </div>
          </li>
        </ul>
        <div class="page">
          <Page
              class="page-tools"
              :size="params.pageSize"
              :current="pageNum"
              :total="total"
              @pageChange="pageChange"
          />
        </div>
      </div>

    </div>
    <NoData :visible="Object.keys(dataSource.data).length<=0"/>
  </div>
</template>
<script>

import api from '@/api'
import NewsHeader from '@/components/common/newsheader'
import {reactive, ref} from 'vue'
import Page from '@/components/common/pagination'
import AudoPlayer from '@/components/story/audio-player'
import {useRoute} from 'vue-router'
import NoData from '@/components/common/nodata'

export default {
  name: '',
  components: {
    NewsHeader,
    Page,
    AudoPlayer,
    NoData
  },
  data () {
    return {
      id: '',
      visible: false,
      townMsg: {},
      news: [],
      industry: [],
      interview: {},
      album: [],
      vr: {},
    }
  },
  methods: {
    goPage (url) {
      if (url && url.includes('http') || url?.includes('www')) {
        window.open(url, '_target')
      } else {
        this.$router.push(url)
      }
    },
  },

  setup () {
    const route = useRoute()
    const videoPlayer = ref()
    const isPlay = ref(false)
    const total = ref(0)
    const pageNum = ref(1)
    const list = ref([])
    const selectedId = ref('')
    let dataSource = reactive({data: {}})
    const townPage = ref(false)//单独一个小镇
    const special = ref(false)//首页进入的的特定故事

    if (route.query?.id) {//首页进入的的特定故事
      special.value = true
    }

    const params = reactive({pageNum: pageNum, pageSize: 5, tabs: 'STORY', townId: route.query.tid ?? null})
    const getData = async () => {
      params.value = {pageNum: pageNum, pageSize: 5, tabs: 'STORY', townId: route.query.tid ?? null}
      let res = await api.townStory(params.value)
      total.value = res?.total ?? 0
      list.value = res?.records ?? []
      if (selectedId.value == '' && total.value > 0) {
        if (special.value == false) {
          selectedId.value = res.records[0].id
          read(selectedId.value)
          storyDetail(selectedId.value)
        }
      }
    }
    const pageChange = (page) => {
      pageNum.value = page
      getData()
    }
    const changeShowIndex = (index) => {
      if (selectedId.value == list.value[index].id)
        return
      selectedId.value = list.value[index].id
      read(selectedId.value)
      storyDetail(selectedId.value)
      isPlay.value = false
      videoPlayer.value.pause()

    }
    const read = (id) => {
      api.readStoryDetail({id: id})
    }
    const storyDetail = async (id) => {
      let res = await api.storyDetail({id: id})
      dataSource.data = res
    }


    if (route.query?.tn) {//单个小镇 隐藏右侧列表
      townPage.value = true
    } else {
      townPage.value = false
    }
    const playClick = () => {
      if (isPlay.value) {
        isPlay.value = !isPlay.value
        videoPlayer.value.pause()
      } else {
        isPlay.value = !isPlay.value
        videoPlayer.value.play()
      }
    }

    getData()
    if (special.value) {
      selectedId.value = route.query?.id
      read(selectedId.value)
      storyDetail(selectedId.value)
    }

    return {
      params,
      total,
      pageNum,
      list,
      pageChange,
      selectedId,
      dataSource,
      changeShowIndex,
      townPage,
      videoPlayer,
      isPlay,
      playClick
    }
  },
}
</script>
<style lang="scss" scoped>
.wrap {
  .header {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;

    .town-pic {
      width: 100%;
      height: 4.48rem;
    }

    .title-img {
      width: 6.4rem;
      height: 1.6rem;
      position: absolute;
    }
  }

  .center-box {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;

    .player-box {
      background: black;
      height: 4.36rem;
      width: 12rem;
      position: relative;

      .video {
        height: 4.36rem;
        width: 12rem;
      }

      .player-cover-box {
        display: flex;
        position: absolute;
        height: 4.36rem;
        width: 12rem;

        top: 0;
        justify-content: center;
        align-items: center;

        .playImg {
          width: 0.97rem;
          height: 0.97rem;
        }

      }
    }

  }

  .center-right-box {
    height: 100%;
    min-height: 10rem;
    background: #ffffff;
    width: 3.47rem;
    position: absolute;
    right: 0;
    padding: 0.11rem;
    box-shadow: 0 0.06rem 0.2rem rgba(0, 0, 0, 0.12);

  }

  .main-content {
    word-break: break-all;
    margin-top: 0.5rem;
    width: 12rem;
    padding-right: 0.12rem;

    & /deep/ img {
      width: 100%;
      max-width: 100%;
    }
  }

  .right-item {
    display: flex;
    flex-direction: column;

    cursor: pointer;

    .right-img {
      width: 1.41rem;
      height: 0.93rem;
      margin-right: 0.06rem;
    }

    .right-item-top-box {
      display: flex;

      .right-item-title {
        font-size: 0.14rem;
        @include text-moreLine(2)
      }

      .right-item-author {
        font-size: 0.12rem;
        color: #999999;
        margin-top: 0.02rem;
        @include text-moreLine(1)
      }

    }

    .right-item-desc {
      font-size: 0.14rem;
      color: #999999;
      margin-top: 0.1rem;
      min-height: 0.3rem;
      @extend .text-oneLine;
    }

    .right-item-bottom-box {
      display: flex;
      align-items: center;

      .right-item-bottom-line {
        height: 0.01rem;
        background: #E1E1E1;
        flex: 1;
      }

      .right-item-date {
        font-size: 0.12rem;
        color: #999999;
        margin-right: 0;
      }
    }

  }

  .page {
    display: flex;
    justify-content: center;
    margin: 0.4rem 0;
    .page-tools {
      padding-bottom: 0.6rem;

    }
  }

}
</style>
