<template>
  <div class="a-box">
    <img :src="data.picture" class="img"/>
    <div class="center-box">
      <div class="center-top-box">
        <p class="title">
          {{ data.title }}
        </p>
        <p class="title">
          {{ fixed(currentTime) }}/{{ fixed(duration) }}
        </p>
      </div>
      <div>
        <Slider
            class="cover-process"
            :value="percent"
            @change="processChange"
            @finish="toggleVolume"
            :trackStyle="{'background':'white'}"
        />
      </div>
    </div>
    <div class="right-box">
      <div class="to-left-box" @click="handleBack">
        <img src="/images/story/toleft.png" class="to-left-img"/>
      </div>
      <div class="circle" @click="click">
        <img src="@/assets/images/audio/pause.png" class="play-img" v-if="isPlay">
        <img src="/images/detail/play.png" class="play-img" v-else>
      </div>
      <div class="to-left-box" @click="handleForward">
        <img src="/images/story/toright.png" class="to-left-img"/>
      </div>
    </div>
  </div>
</template>

<script>
import {Slider} from 'ant-design-vue'

export default {
  name: 'audio-player',
  props: {
    data: Object
  },
  components: {
    Slider
  },
  data () {
    return {
      visible: false,
      audio: null,
      isPlay: false,
      percent: 0,
      volume: 50,
      duration: 0,
      currentTime: 0,
      forwardSecond:10,
    }
  },
  methods: {
    fixed (text) {
      let minute = parseInt(parseInt(text) / 60)
      let second = parseInt(parseInt(text) % 60)
      return `${minute < 10 ? '0' : ''}${minute}:${second < 10 ? '0' : ''}${second}`
    },
    forbid (e) {
      e.preventDefault()
    },
    play () {
      this.audio.play()
    },
    pause () {
      this.audio.pause()
      this.isPlay = false
    },
    playing () {
      this.isPlay = true
    },
    timeupdate () {
      this.currentTime = this.audio.currentTime
      this.percent = this.currentTime * 100 / this.duration
    },
    ended () {
      this.isPlay = false
      this.percent = 0
      this.currentTime = 0
    },
    loadedMetaData () {
      this.duration = this.audio.duration
    },
    volumeChange (value) {
      this.volume = value
      this.audio.volume = value / 100
    },
    toggleVolume () {
      this.visible = !this.visible
    },
    processChange (value) {

      this.audio.currentTime = value / 100 * this.duration
    },
    initAudio () {
      console.log('-initAudio------')
      this.audio = document.createElement('audio')
      this.audio.src = this.data.video
      this.audio.volume = this.volume / 100
      this.audio.addEventListener('playing', this.playing)
      this.audio.addEventListener('timeupdate', this.timeupdate)
      this.audio.addEventListener('ended', this.ended)
      this.audio.addEventListener('loadedmetadata', this.loadedMetaData)
    },
    handleForward(){
        if (this.audio.duration - this.audio.currentTime > this.forwardSecond) {
          this.audio.currentTime = this.audio.currentTime + this.forwardSecond;
        }
    },
    handleBack(){
      if (this.audio.currentTime > this.forwardSecond) {
        this.audio.currentTime = this.audio.currentTime - this.forwardSecond;
      }else {
        this.audio.currentTime = 0;
      }
    },
    click () {
      if (this.isPlay) {
        this.pause()
      } else {
        this.play()
      }
    }
  },
  mounted () {
    this.initAudio()
  },
  watch: {
    data(newer){

      this.pause();
      this.audio.currentTime = 0;
      this.initAudio();

    }
  }
}
</script>

<style lang="scss" scoped>
.a-box {
  width: 12rem;
  height: 0.91rem;

  display: flex;

  .img {
    width: 1.9rem;
    height: 100%;
  }

  .center-box {
    width: 7.82rem;
    height: 100%;
    background: #01398D;
    display: flex;
    flex-direction: column;
    padding: 0 0.45rem;
    justify-content: center;

    .center-top-box {
      display: flex;
      justify-content: space-between;

      .title {
        color: #FFFFFF;
        font-size: 0.14rem;
        @include text-moreLine(1)
      }
    }

  }

  .right-box {
    flex: 1;
    background: #01398D;
    display: flex;
    justify-content: center;
    align-items: center;

    .to-left-box {
      padding: 0.2rem 0.15rem;
      cursor: pointer;
      .to-left-img {
        width: 0.16rem;
        height: 0.18rem;
      }
    }

    .circle {
      width: 0.4rem;
      height: 0.4rem;
      border-radius: 0.2rem;
      background: transparent;
      border: 0.02rem solid #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      margin: 0 0.05rem;
    }

    .play-img {
      width: 0.14rem;
      height: 0.17rem;
    }

  }

  /deep/ .ant-slider-rail {
    background: #2F5B9D;
  }
}

</style>
